import React, { useRef, useState } from 'react';
import APIClient from '../../backend/api';

import styles from './index.module.css';

interface Props {
  apiClient: APIClient;
}

export default function ExplainIngredients(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [productDescription, setProductDescription] = useState<string>('');
  const [ingredients, setIngredients] = useState<{name: string, description: string}[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  
  return <div className={styles['container']}>
    <div className={styles['ei_content-container']}>
    {loading ? <div className={styles['ei_loading-container']}><Loading/></div> : null}
    {!loading ? <div className={styles['ei_upload-container']}>
      <div className={styles['ei_upload-disclaimer']}>
        <span>Snap a picture or upload an image showing the ingredients of a product, and we'll break down those ingredients in simple, easy-to-understand terms for you.</span>
      </div>
      <div className={styles['ei_upload-btn-container']}>
      <button className={styles['ei_upload-button']} onClick={() => {
        inputRef.current?.click();
      }}>Choose or take a photo</button>
      <input ref={inputRef} name="photo" type="file" hidden={true} accept="image/png, image/jpeg" onChange={async (e) => {
        if(e.target.files?.length){
          try {
            setLoading(true);
            setProductDescription('');
            setIngredients([]);
            setErrorMsg('');
            const resp = await props.apiClient.explainIngredients(e.target.files[0]);

            if(resp.error) {
              setErrorMsg(resp.error);
              return;
            }
            
            if(!resp.ingredients && !resp.product_description) {
              setErrorMsg('failed, please upload and try again.');
              return;
            }

            if(resp.ingredients) {
              setIngredients(resp.ingredients);
            }

            if(resp.product_description) {
              setProductDescription(resp.product_description);
            }
          } catch (err) {
            setErrorMsg('failed, please upload and try again.');
          } finally {
            setLoading(false);
          }
        }
      }}/>
      </div>
      <div>
        <span className={styles['ei_error']}>{errorMsg}</span>
      </div>
    </div> : null}
    {productDescription ? <div>
      <h1>Product description</h1>
      <span>{productDescription}</span>
    </div> : null}
    {ingredients && ingredients.length ? <div>
      <h1>Ingredients</h1>
      {ingredients.map(item => {
        return <div>
          <h3>{item.name}</h3>
          <span>{item.description}</span>
        </div>
      })}
    </div> : null}
    </div>
    <div className={styles['ei_footer']}></div>
  </div>
}

function Loading() {
  return <img className={styles['ei_loading']} src="./assets/loading.svg" alt="loading..." />;
}